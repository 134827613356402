// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-collect-js": () => import("./../../../src/pages/collect.js" /* webpackChunkName: "component---src-pages-collect-js" */),
  "component---src-pages-find-js": () => import("./../../../src/pages/find.js" /* webpackChunkName: "component---src-pages-find-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-items-js": () => import("./../../../src/pages/items.js" /* webpackChunkName: "component---src-pages-items-js" */),
  "component---src-pages-makefire-js": () => import("./../../../src/pages/makefire.js" /* webpackChunkName: "component---src-pages-makefire-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

